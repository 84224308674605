/* JOURNY SECTION START */
.journy-card-item_section {
  width: 375px;
}

.journy-card-position {
  position: sticky;
  top: 200px;
}

.journy-card-item_section.vertical .journy-cards_title {
  transform: rotate(-90deg);
  white-space: nowrap;
  transition: 0.1s ease;
}

.journy-cards_title {
  transition: 0.1s ease;
  transform: rotate(0deg);
}

.journy-card-item_section.vertical .journy-cards_description {
  display: none;
}

.journy-card-item_section .journy-cards_descriptionNone {
  display: none;
}

/* JOURNY SECTION END */

/* FLAVOURS SECTION START */

.flavors-card-item_section {
  width: 375px;
}

.flavors-card-item_section.vertical .flavors-cards_title {
  transform: rotate(-90deg);
  white-space: nowrap;
  transition: 0.1s ease;
}

.flavors-cards_title {
  transition: 0.1s ease;
  transform: rotate(0deg);
}

.flavors-card-item_section.vertical .flavors-cards_description {
  display: none;
}

.swiper-pagination-bullet {
  width: 25px !important;
  height: 25px !important;
  text-align: center;
  line-height: 25px;
  font-size: 1rem;
  color: #ffffff !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.3) !important;
}

.swiper-pagination-bullet-active {
  color: #fff !important;
  background: #1fa60f !important;
}
