@font-face {
  font-family: "neuemontreal-medium";
  src: local("neuemontreal-medium"),
    url("./Helper/Fonts/NeueMontreal-Medium.otf") format("opentype");
}

body {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box;
  font-family: "neuemontreal-medium";
}

p,
button,
span,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
div,
label,
q {
  font-family: "neuemontreal-medium" !important;
}

::-webkit-scrollbar {
  width: 0.375rem;
  display: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #bfbfd5;
}

::-webkit-scrollbar-track {
  border-radius: 20px;
  background: transparent;
}

.bold_text {
  font-family: "neuemontreal-medium" !important;
}

.svg-map {
  width: 90% !important;
  stroke-width: 1;
  height: 95vh !important;
}

@media screen and (min-width: 1250px) {
  .spice_img {
    height: 32rem !important;
  }

  .btobsolutioncard_img {
    height: 15rem !important;
  }

  .welcome_tagline {
    font-size: 75px !important;
  }

  .quiality_content_wrap {
    width: 45% !important;
  }

  .products_fade {
    width: 100% !important;
  }

  .product_cards {
    width: 80% !important;
    margin: auto;
  }

  .recipesCard_img {
    height: 15rem !important;
  }
}

.MuiAutocomplete-popper {
  z-index: 9999999 !important
}

@media screen and (min-width: 1450px) {
  .quality_img img {
    height: 30rem !important;
  }

  .benefits_sec_img {
    height: 45rem !important;
  }

  .res-wheel {
    scale: 1.2;
  }

  /* .res-wheel-inner {
    left: 0 !important;
  } */

  .our-promise-sec {
    align-items: center !important;
  }
}

@media screen and (min-width: 1800px) {
  .deeperanalysis_sec {
    margin-top: 230px !important;
  }

  .btobsolutioncard_img {
    height: 25rem !important;
  }

  .recipesCard_img {
    height: 25rem !important;
  }
}

@keyframes blink {
  0% {
    fill: #a1d99b;
  }

  50% {
    fill: #ffffff;
  }

  100% {
    fill: #a1d99b;
  }
}

.blink {
  animation: blink 1s infinite;
}

.svg-map__location {
  cursor: default !important;
}

.svg-map__location.blink {
  cursor: pointer !important;
}

@media screen and (max-width: 580px) {
  .svg-map {
    height: 60vh !important;
  }
}

@media screen and (min-width: 2000px) {
  .main-box-process {
    height: 82rem !important;
  }

  .res-wheel {
    scale: 1.9;
  }

  .spice_img {
    height: 53rem !important;
  }

  .signature_img {
    height: 45rem !important;
  }
}